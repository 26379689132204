var site = site || {};
site.nav = site.nav || {};

site.nav = {
  classes: {
    test: 'tests',
    container: '.js-site-header',
    site: '.js-site',
    hamburger: '.js-hamburger',
    nav: 'js-site-nav',
    submenus: '.js-submenu'
  },
  nodes: {
    container: '',
    site: '',
    hamburger: '',
    nav: '',
    submenus: ''
  },
  setContainer: function() {
    this.nodes.container = document.querySelector(this.classes.container);
    var hasContainer = (this.nodes.container === null) ? 0 : 1;
    return hasContainer;
  },
  getNodes: function() {
    this.nodes.site = document.querySelector(this.classes.site);
    this.nodes.hamburger = document.querySelector(this.classes.hamburger);
    this.nodes.submenus = document.querySelectorAll(this.classes.submenus);
  },
  setEvents: function() {
    var that = this;
    that.nodes.hamburger.addEventListener('click', function(event) {
      event.preventDefault();
      that.nodes.site.classList.toggle('nav-active');
    });
    for (var i = 0; i < that.nodes.submenus.length; i++) {
      that.nodes.submenus[i].addEventListener('click', function(event) {
        event.stopImmediatePropagation();
        this.classList.toggle('menu-active');
      });
    }
  },
  init: function(args) {
    if (!this.setContainer()) {
      return false;
    }
    this.getNodes();
    this.setEvents();
  }
};

document.addEventListener('DOMContentLoaded', function() {
  site.nav.init();
});
