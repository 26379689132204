var site = site || {};
site.gallery = site.gallery || {};

/*
 * Gallery set-up using slide.js responsive library.
 * Used https://splidejs.com/gallery/ as reference.
 */
site.gallery = {
  classes: {
    container: '.js-image-gallery'
  },
  nodes: {
    container: ''
  },
  getNodes: function() {
    this.nodes.container = document.querySelector(this.classes.container);
  },
  setMainGallery: function() {
    if (!this.nodes.container) {
      return null;
    }

    var splide = new Splide( this.nodes.container, {
      type: 'loop',
      pagination : false
    } );

    var images = document.querySelectorAll( '.js-gallery-thumbnails li' );

    var activeImage;
    var activeClass = 'is-active';

    for ( let i = 0, len = images.length; i <= len; i++ ) {
      var image = images[ i ];

      splide.on( 'click', function () {
        if ( activeImage !== image ) {
          splide.go( i );
        }
      }, image );
    }

    splide.on( 'mounted move', function ( newIndex ) {
      // newIndex will be undefined through the "mounted" event.
      var image = images[ newIndex !== undefined ? newIndex : splide.index ];

      if ( image && activeImage !== image ) {
        if ( activeImage ) {
          activeImage.classList.remove( activeClass );
        }

        image.classList.add( activeClass );
        activeImage = image;
      }
    } );

    splide.mount();
  },
  init: function() {
    this.getNodes();
    this.setMainGallery();
  }
};

document.addEventListener('DOMContentLoaded', function() {
  site.gallery.init();
});
